import {Formik} from 'formik'
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import Swal from 'sweetalert2'
import ButtonAuthSubmit from '../../../../_metronic/layout/components/buttons/ButtonAuthSubmit'
import {Select, TextInput, TextInputMask} from '../../../widgets/form'
import {login} from '../redux/AuthCRUD'
import * as auth from '../redux/AuthRedux'
import axios from 'axios'
import {GetEventsAvailableSelectModel} from '../../../models/selects'
import {codEventMask} from '../../../masks'
import {TextInputPassword} from '../../../widgets/form/TextInputPassword'

const validationSchema = Yup.object().shape({
    cd_event: Yup.string().when('event_code', {
        is: 'select',
        then: Yup.string().required('Cód. do evento é obrigatório'),
    }),
    event_code: Yup.string().required('Evento é obrigatório'),
    email: Yup.string()
        .email('Entre com um e-mail válido')
        .min(3, 'Mínimo de 3 caractéres')
        .max(50, 'Máximo de 50 caractéres')
        .required('E-mail é obrigatório'),
    password: Yup.string()
        .min(3, 'Mínimo 3 caractéres')
        .max(50, 'Máximo 50 caractéres')
        .required('Senha é obrigatório'),
})

const initialValues = {
    email: '',
    password: '',
    event_code: '',
    cd_event: '',
}

export function Login() {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [events, setEvents] = useState<GetEventsAvailableSelectModel>()
    const [inputType, setInputType] = useState('password')
    const [eye, setEye] = useState('bi bi-eye-slash-fill')

    function toggleEye(e: EventTarget, eye: string) {
        if (eye === 'bi bi-eye-fill') {
            setEye('bi bi-eye-slash-fill')
            setInputType('password')
            return
        } else if (eye === 'bi bi-eye-slash-fill') {
            setEye('bi bi-eye-fill')
            setInputType('text')
            return
        }
    }

    async function getEventsAvailable() {
        const {data} = await axios.get<GetEventsAvailableSelectModel>(
            `/ms-admin/functions/get-events-available`
        )
        setEvents(data)
    }
    useEffect(() => {
        getEventsAvailable()
    }, [])

    async function handleSubmit(values: typeof initialValues) {
        try {
            setLoading(true)

            var event_code = values.event_code === 'select' ? values.cd_event : values.event_code
            const {data} = await login({
                email: values.email,
                password: values.password,
                event_code: event_code,
            })
            dispatch(
                auth.actions.login(
                    data.data.tokens.access_token,
                    data.data.tokens.expires_at,
                    event_code,
                    data.data.event.nm_event
                )
            )
        } finally {
            setLoading(false)
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {(props) => (
                <form className='form w-100' onSubmit={props.handleSubmit} noValidate>
                    <div className='mb-5 bg-light p-5 rounded text-center'>
                        <span className='text-dark h3 text-justify'>
                            Bem-vindo(a) a Kachin!
                            <br />
                            Gestão para seu evento.
                        </span>
                    </div>
                    <div className='fv-row mb-5'>
                        <Select label='Evento' name='event_code' formik={props}>
                            {events?.data.select.map((item) => (
                                <option key={item.cd_event} value={item.cd_event}>
                                    {item.nm_event}
                                </option>
                            ))}
                            <option key={'enter-code'} value={'select'}>
                                {'Outro evento'}
                            </option>
                        </Select>
                    </div>
                    {props.values.event_code === 'select' && (
                        <div className='fv-row mb-5'>
                            <TextInputMask
                                name='cd_event'
                                formik={props}
                                label='Cód. Evento'
                                placeholder='Ex: 000000'
                                mask={codEventMask}
                            />
                        </div>
                    )}
                    <div className='fv-row mb-5'>
                        <TextInput
                            name='email'
                            formik={props}
                            label='E-mail'
                            type='email'
                            placeholder='Ex: contato@kachin.com.br'
                        />
                    </div>
                    <div className='fv-row mb-5'>
                        <TextInputPassword
                            name='password'
                            placeholder='Entre com a senha'
                            label='Senha'
                            type={inputType}
                            eye={eye}
                            toggleEye={toggleEye}
                        />
                    </div>
                    <div className='text-center'>
                        <ButtonAuthSubmit loading={loading} buttonTitle='Acessar plataforma' />
                        <button
                            type='button'
                            id='kt_login_password_reset_form_cancel_button'
                            className='btn btn-lg btn-secondary w-100'
                            onClick={() =>
                                Swal.fire({
                                    icon: 'warning',
                                    title: 'Esqueceu a senha?',
                                    html: 'Dirija-se à administração do evento e solicite um administrador para realizar a alteração de senha!',
                                    confirmButtonText: 'Recebido!',
                                    confirmButtonColor: '#122E33',
                                    allowOutsideClick: false,
                                })
                            }
                        >
                            Esqueceu a senha?
                        </button>
                    </div>
                </form>
            )}
        </Formik>
    )
}
