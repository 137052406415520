import axios from 'axios'
import {LoginResponse} from '../models/AuthModel'
import {ProfileResponse} from '../models/ProfileModel'
export const GET_USER_BY_ACCESSTOKEN_URL = `/profile/show`
export const LOGIN_URL = `/auth/login`
export const LOGOUT_URL = `/auth/logout`

// Server should return AuthModel
interface Login {
    email: string
    password: string
    event_code: string
}
export function login({email, password, event_code}: Login) {  
    return axios.post<LoginResponse>(LOGIN_URL, {email, password, event_code})
}

export function logout() {
    return axios.get(LOGOUT_URL)
}

export function getUserByToken() {
    // Authorization head should be fulfilled in interceptor.
    // Check common redux folder => setupAxios
    return axios.get<ProfileResponse>(GET_USER_BY_ACCESSTOKEN_URL)
}
