import {useState} from 'react'
import { ButtonSubmit } from '../form'
import ButtonNavigation from './components/ButtonNavigation'
import {props} from './models'

export default function InternalNavigation({
    loading = false,
    pages,
    labels,
    buttonNext,
    titleSubmit,
}: props) {
    const keys = Object.keys(pages)
    const [page, setPage] = useState(keys[0])

    function nextPage() {
        const index = keys.indexOf(page)
        if (index < keys.length - 1) {
            setPage(keys[index + 1])
        }
    }

    function inLastPage() {
        return keys.indexOf(page) === keys.length - 1
    }

    return (
        <div className='d-flex flex-column flex-md-row'>
            <menu className='m-0 p-0'>
                <ul className='p-0 me-6'>
                    {keys.map((key, index) => (
                        <ButtonNavigation
                            onClick={() => setPage(key)}
                            label={labels[index]}
                            active={page === key}
                            key={key}
                        />
                    ))}
                </ul>
            </menu>
            <main className='w-100'>
                {pages[page]}
                {buttonNext && (
                    <div className='d-flex w-100 justify-content-end my-4'>
                        {!inLastPage() ? (
                            <button type='button' className='btn btn-light-primary' onClick={() => nextPage()}>
                                <i className='fas fa-arrow-right' />
                                Próxima
                            </button>
                        ) : (
                            <ButtonSubmit loading={loading} title={titleSubmit} />
                        )}
                    </div>
                )}
            </main>
        </div>
    )
}
