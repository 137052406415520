interface Props {
    active: boolean
    label: string
    onClick: () => void
}
export default function ButtonNavigation({active, label, onClick}: Props) {
    return (
        <li className='list-unstyled my-2'>
            <button
                type='button'
                onClick={onClick}
                className={`w-175px btn btn-${active ? 'primary' : 'light-primary'}`}
            >
                {label}
            </button>
        </li>
    )
}
