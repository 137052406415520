import clsx from 'clsx'
import {FormikProps} from 'formik'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    touched?: boolean | undefined
    error?: string | undefined
    name: string
    label?: string
    value?: string | undefined
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
    formik?: FormikProps<any>
    required?: boolean
    className?: string
}
export function TextInputColor({
    touched,
    error,
    name,
    label,
    value,
    onChange,
    onBlur,
    formik,
    required = false,
    className,
    ...props
}: Props) {
    return (
        <>
            {label && (
                <label htmlFor={name} className='form-label fs-6 fw-bolder'>
                    {label}
                    {required && <span className='text-danger'>*</span>}
                </label>
            )}
            <input
                {...props}
                className={clsx(
                    'form-control form-control-solid',
                    {
                        'is-invalid':
                            (formik?.touched[name] && formik?.errors[name]) || (touched && error),
                    },
                    {
                        'is-valid':
                            (formik?.touched[name] && formik?.errors[name]) || (touched && !error),
                    },
                    className
                )}
                style={{height: '44px'}}
                type='color'
                id={name}
                name={name}
                value={formik ? formik.values[name] : value}
                onChange={formik ? formik.handleChange : onChange}
                onBlur={formik ? formik.handleBlur : onBlur}
            />
            {(formik?.touched[name] && formik?.errors[name]) || error ? (
                <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                        <span role='alert' className='text-danger'>
                            {formik?.errors[name] || error}
                        </span>
                    </div>
                </div>
            ) : null}
        </>
    )
}
