import clsx from 'clsx'
import {Field} from 'formik'
import MaskedInput from 'react-text-mask'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    name: string
    label?: string
    type?: string
    placeholder?: string
    value?: string
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
    required?: boolean
    mask: (RegExp | string)[]
}
export function TextInputMaskNew({
    name,
    label,
    type = 'text',
    placeholder,
    value,
    onChange,
    onBlur,
    required = false,
    mask,
    ...props
}: Props) {
    return (
        <>
            {label && (
                <label htmlFor={name} className='form-label fs-6 fw-bolder'>
                    {label}
                    {required && <span className='text-danger'>*</span>}
                </label>
            )}
            <Field name={name} id={name}>
                {({form}: any) => {
                    return (
                        <>
                            <MaskedInput
                                {...props}
                                mask={mask}
                                id={name}
                                placeholder={placeholder}
                                type={type}
                                value={value || form.values[name]}
                                onChange={onChange || form.handleChange}
                                onBlur={onBlur || form.handleBlur}
                                className={clsx(
                                    'form-control form-control-lg form-control-solid',
                                    {
                                        'is-invalid': form?.touched[name] && form?.errors[name],
                                    },
                                    {
                                        'is-valid': form?.touched[name] && form?.errors[name],
                                    }
                                )}
                            />
                            {form.touched[name] && form.errors[name] && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert' className='text-danger'>
                                            {form.errors[name]}
                                        </span>
                                    </div>
                                </div>
                            )}
                        </>
                    )
                }}
            </Field>
        </>
    )
}
