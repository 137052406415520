import clsx from 'clsx'
import {FC} from 'react'
import {Link} from 'react-router-dom'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
    toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
    toolbarButtonIconSizeClass = 'svg-icon-1'

export const HeaderEventMenu: FC = () => {
    return (
        <>
            <div
                className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
                style={{zIndex: 1}}
            >
                <Link
                    to='/evento'
                    className={clsx(
                        'cursor-pointer symbol btn btn-icon btn-active-success',
                        toolbarUserAvatarHeightClass
                    )}
                >
                    <i
                        className={
                            toolbarButtonIconSizeClass + ' fas fa-globe-americas fa-2x text-white'
                        }
                    ></i>
                </Link>
            </div>
        </>
    )
}
