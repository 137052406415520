import {lazy} from 'react'
import {Route, Switch} from 'react-router-dom'

export function AttractiveRoutes() {
    /*Rotas para Sorteio*/
    const DatatableRaffle = lazy(() => import('../../pages/attractive/raffle/DatatableRaffle'))

    /*Rotas para Patrocinadores*/
    const DatatableSponsor = lazy(() => import('../../pages/attractive/sponsor/DatatableSponsor'))

    /*Rotas para Promoções*/
    const DatatableSale = lazy(() => import('../../pages/attractive/sale/DatatableSale'))
    const ShowSale = lazy(() => import('../../pages/attractive/sale/ShowSale'))

    return (
        <Switch>
            {/*Rotas para Sorteio*/}
            <Route path='/atrativos/sorteio' component={DatatableRaffle} />

            {/*Rotas para Patrocinadores*/}
            <Route path='/atrativos/patrocinadores' component={DatatableSponsor} />

            {/*Rotas para Promoções*/}
            <Route path='/atrativos/promocoes/:id/visualizar' component={ShowSale} />
            <Route path='/atrativos/promocoes' component={DatatableSale} />
        </Switch>
    )
}
