interface Props {
    title: string;
    status: boolean
}

export default function TableDataConfigShowEventComponent({status, title}:Props){
    return(
        <tr>
            <td>{title}</td>
            <td className='text-center'>
                <span
                    style={{width: 'fit-content'}}
                    className={`badge-light-${
                        status ? 'success' : 'danger'
                    } text-center m-0 p-2 rounded`}
                >
                    {status ? 'Sim' : 'Não'}
                </span>
            </td>
        </tr>
    )
}