import clsx from 'clsx'
import {Field, FormikProps} from 'formik'
import MaskedInput from 'react-text-mask'

interface Props {
    touched?: boolean
    error?: string
    name: string
    label?: string
    type?: string
    placeholder?: string
    value?: string
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
    required?: boolean
    readOnly?: boolean
    formik?: FormikProps<any>
    mask: (RegExp | string)[]
}
export function TextInputMask({
    touched,
    error,
    name,
    label,
    type = 'text',
    placeholder,
    value,
    onChange,
    onBlur,
    required = false,
    readOnly = false,
    formik,
    mask,
}: Props) {
    return (
        <>
            {label && (
                <label htmlFor={name} className='form-label fs-6 fw-bolder'>
                    {label}
                    {required && <span className='text-danger'>*</span>}
                </label>
            )}
            <Field name={name} id={name}>
                {({field}: any) => (
                    <MaskedInput
                        {...field}
                        mask={mask}
                        id={name}
                        readOnly={readOnly}
                        placeholder={placeholder}
                        type={type}
                        value={formik ? formik.values[name] : value}
                        onChange={formik ? formik.handleChange : onChange}
                        onBlur={formik ? formik.handleBlur : onBlur}
                        className={clsx(
                            'form-control form-control-solid',
                            {
                                'is-invalid':
                                    (formik?.touched[name] && formik?.errors[name]) ||
                                    (touched && error),
                            },
                            {
                                'is-valid':
                                    (formik?.touched[name] && formik?.errors[name]) ||
                                    (touched && !error),
                            }
                        )}
                    />
                )}
            </Field>
            {(formik?.touched[name] && formik?.errors[name]) || error ? (
                <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                        <span role='alert' className='text-danger'>
                            {formik?.errors[name] || error}
                        </span>
                    </div>
                </div>
            ) : null}
        </>
    )
}
