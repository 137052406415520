import {useState} from 'react'
import {GetEventModel} from '../models'
import CardHeader from '../../../widgets/cards/CardHeader'
import UpdateEventAddressModal from '../modals/UpdateEventAddressModal'

interface Props {
    event: GetEventModel
    getData: () => void
}

export default function AddressShowEventSubpage({event, getData}: Props) {
    const [isOpenEdit, setIsOpenEdit] = useState(false)

    return (
        <>
            <div className='card'>
                <CardHeader title='Endereço'>
                    <button
                        className='btn btn-sm btn-light-info me-2'
                        onClick={() => setIsOpenEdit(true)}
                    >
                        <i className='fa fa-edit'></i>
                        Editar
                    </button>
                </CardHeader>
                <div className='card-body'>
                    <div className='row mb-2'>
                        <div className='col row'>
                            <div className='row mb-2'>
                                <div className='col'>
                                    <label>CEP</label>
                                    <h3>{event.data.event.address?.ds_cep ?? '-'}</h3>
                                </div>
                                <div className='col-9'>
                                    <label>Local</label>
                                    <h3>{event.data.event.address?.ds_reference ?? '-'}</h3>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-lg-6'>
                                    <label>Logradouro</label>
                                    <h3>{event.data.event.address?.ds_street ?? '-'}</h3>
                                </div>
                                <div className='col-lg-3'>
                                    <label>Número</label>
                                    <h3>{event.data.event.address?.ds_number ?? '-'}</h3>
                                </div>
                                <div className='col-lg-3'>
                                    <label>Complemento</label>
                                    <h3>{event.data.event.address?.ds_complement ?? '-'}</h3>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-lg-3'>
                                    <label>Bairro</label>
                                    <h3>{event.data.event.address?.ds_neighborhood ?? '-'}</h3>
                                </div>
                                <div className='col-lg-3'>
                                    <label>Cidade</label>
                                    <h3>{event.data.event.address?.ds_city ?? '-'}</h3>
                                </div>
                                <div className='col-lg-3'>
                                    <label>Estado</label>
                                    <h3>{event.data.event.address?.ds_state ?? '-'}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <UpdateEventAddressModal
                isOpen={isOpenEdit}
                onClose={() => setIsOpenEdit(false)}
                event={event.data.event}
                onUpdate={() => getData()}
            />
        </>
    )
}
