import axios from 'axios'
import clsx from 'clsx'
import Pusher from 'pusher-js'
import {useEffect, useState} from 'react'
import {usePopper} from 'react-popper'
import {shallowEqual, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import Swal from 'sweetalert2'
import {UserModel} from '../../../../app/models'
import {RootState} from '../../../../setup'
import store from '../../../../setup/redux/Store'
import {toAbsoluteUrl} from '../../../helpers'
import Notification from './Notification'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
    toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
    toolbarButtonIconSizeClass = 'svg-icon-1'

interface Message {
    id: string
    type: string
    notifiable_type: string
    notifiable_id: number
    color?: string
    icon?: string
    data: {
        title: string
    }
    read_at: null | Date
    created_at: string
    updated_at: Date
}

export function HeaderNotificationsMenu() {
    const [showPopper, setShowPopper] = useState(false)
    const [referenceElement, setReferenceElement] = useState<any>(null)
    const [popperElement, setPopperElement] = useState<any>(null)
    const {styles, attributes} = usePopper(referenceElement, popperElement, {
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 8],
                },
            },
        ],
    })

    const [notifications, setNotifications] = useState<Message[]>([])
    const [someNotRead, setSomeNotRead] = useState(false)
    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

    async function getNotification() {
        const {data} = await axios.get('/profile/notifications/index')
        setNotifications(data.data.notifications)
        setSomeNotRead(data.data?.notifications?.some((item: Message) => !item.read_at))
    }

    useEffect(() => {
        const {
            auth: {event_code},
        } = store.getState()

        const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY || '', {
            cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
            forceTLS: false,
            authEndpoint: process.env.REACT_APP_PUSHER_APP_ENDPOINT,
            authTransport: 'ajax',
            auth: {
                params: {
                    email: user.email,
                    event_code: event_code,
                },
            },
        })

        const channel = pusher.subscribe(`private-${event_code}.App.Models.User.${user.id}`)
        channel.bind(process.env.REACT_APP_PUSHER_EVENT_NAME || '', function (data: Notification) {
            Swal.fire({
                icon: data.data.type ?? 'success',
                title: data.data.title,
                html: data.data.description,
                confirmButtonText: 'Recebido!',
                confirmButtonColor: '#122E33',
                allowOutsideClick: false,
            })
            var audio = new Audio(toAbsoluteUrl('/media/sounds/alert.mp3'))
            audio.play()
            getNotification()
        })

        return () => {
            pusher.unsubscribe(`private-App.Models.Admin.${user.id}`)
        }
    }, [user])

    useEffect(() => {
        getNotification()
    }, [])

    return (
        <>
            {showPopper && (
                <div
                    className='w-100 h-100'
                    style={{
                        zIndex: 0,
                        position: 'fixed',
                        top: 0,
                        left: 0,
                    }}
                    onClick={() => setShowPopper(false)}
                />
            )}
            <div
                className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
                style={{zIndex: 1}}
            >
                <button
                    ref={(ref) => setReferenceElement(ref)}
                    onClick={() => setShowPopper((oldValue) => !oldValue)}
                    className={clsx(
                        'cursor-pointer symbol btn btn-icon btn-active-success',
                        toolbarUserAvatarHeightClass
                    )}
                >
                    <i className={toolbarButtonIconSizeClass + ' fas fa-bell fa-2x text-white'}></i>
                    {someNotRead && (
                        <>
                            <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'></span>
                            <span className='pulse-ring'></span>
                        </>
                    )}
                </button>
                {showPopper && (
                    <div
                        className='menu menu-column rounded bg-white menu-gray-600 menu-state-bg menu-state-primary fw-bold fs-6 w-350px shadow'
                        ref={(ref) => setPopperElement(ref)}
                        style={{...styles.popper, marginLeft: -16}}
                        {...attributes.popper}
                    >
                        <div className='d-flex flex-column bgi-no-repeat rounded-top bg-primary'>
                            <h3 className='text-white fw-bold px-9 my-5'>Notificações</h3>
                        </div>

                        <div className='scroll-y mh-325px my-3 px-2'>
                            {notifications?.length !== 0 ? (
                                notifications?.map((notification, index) => (
                                    <Notification
                                        created_at={notification.created_at}
                                        title={notification.data.title}
                                        color={notification.color}
                                        icon={notification.color}
                                        key={`not-${index}`}
                                    />
                                ))
                            ) : (
                                <p className='text-center my-5'>
                                    Nenhuma notificação disponível! <br /> Você não possui
                                    notificações.
                                </p>
                            )}
                        </div>

                        <div className='py-3 text-center border-top'>
                            <Link
                                to='/perfil/notificacoes'
                                className='btn btn-color-gray-600 btn-active-color-primary align-items-center'
                            >
                                Ver todas
                                <i className='ms-2 fas fa-arrow-circle-right'></i>
                            </Link>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}
