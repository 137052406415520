import axios from 'axios'
import 'leaflet/dist/leaflet.css'
import {useCallback, useEffect, useState} from 'react'
import {LoadingContainer} from '../../widgets/loadings/LoadingContainer'
import InternalNavigation from '../../widgets/internalNavigation/InternalNavigation'
import {GetEventModel} from './models'
import InfoShowEventSubpage from './subpages/InfoShowEventSubpage'
import ConfigsShowEventSubpage from './subpages/ConfigsShowEventSubpage'
import EmptyPageWidget from '../../widgets/errors/EmptyPageWidget'
import AddressShowEventSubpage from './subpages/AddressShowEventSubpage'

export default function EventPage() {
    const [event, setPerson] = useState<GetEventModel>()
    const [loading, setLoading] = useState(true)

    const getData = useCallback(async () => {
        try {
            const {data} = await axios.get<GetEventModel>(`/event/show`)
            setPerson(data)
        } finally {
            setLoading(false)
        }
    }, [])

    useEffect(() => {
        getData()
    }, [getData])

    return (
        <div className='row'>
            <LoadingContainer loading={loading}>
                {event !== undefined ? (
                    <InternalNavigation
                        labels={['Informações', 'Endereço', 'Configurações']}
                        pages={{
                            info: <InfoShowEventSubpage event={event} getData={() => getData()} />,
                            address: (
                                <AddressShowEventSubpage event={event} getData={() => getData()} />
                            ),
                            configs: (
                                <ConfigsShowEventSubpage event={event} getData={() => getData()} />
                            ),
                        }}
                        titleSubmit='Cadastrar'
                        buttonNext={false}
                    />
                ) : (
                    <EmptyPageWidget code={404} title='Não encontrado' />
                )}
            </LoadingContainer>
        </div>
    )
}
