import axios from 'axios'
import {Formik} from 'formik'
import {useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {toast} from 'react-toastify'
import * as Yup from 'yup'

import {EventModel, ValuesUpdateEventAddress} from '../models'
import {ButtonSubmit, TextInput, TextInputMask} from '../../../widgets/form'
import {cepEventMask} from '../../../masks'

interface Props {
    onClose: (isOpen: boolean) => void
    event: EventModel
    onUpdate: () => void
    isOpen: boolean
}

const validationSchema = Yup.object({
    ds_cep: Yup.string().required('Campo obrigatório!'),
    ds_reference: Yup.string().required('Campo obrigatório!'),
    ds_street: Yup.string().required('Campo obrigatório!'),
    ds_neighborhood: Yup.string().required('Campo obrigatório!'),
    ds_city: Yup.string().required('Campo obrigatório!'),
    ds_state: Yup.string().required('Campo obrigatório!'),
})

export default function UpdateEventAddressModal({onClose, isOpen, event, onUpdate}: Props) {
    const [loading, setLoading] = useState(false)

    const initialValues = {
        ds_cep: event.address?.ds_cep || '',
        ds_reference: event.address?.ds_reference || '',
        ds_street: event.address?.ds_street || '',
        ds_number: event.address?.ds_number || '',
        ds_complement: event.address?.ds_complement || '',
        ds_neighborhood: event.address?.ds_neighborhood || '',
        ds_city: event.address?.ds_city || '',
        ds_state: event.address?.ds_state || '',
    }

    async function handleUpdate(values: ValuesUpdateEventAddress) {
        try {
            setLoading(true)
            const {data} = await axios.put('/event/update/address', values)
            console.log(data)
            toast.success(data.message)
            onUpdate()
            onClose(false)
        } finally {
            setLoading(false)
        }
    }
    return (
        <Modal
            onHide={() => onClose(false)}
            show={isOpen}
            aria-labelledby='contained-modal-title-center'
            size='lg'
            centered
        >
            <Modal.Header>
                <Modal.Title id='contained-modal-title-center'>
                    Editar dados de endereço
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {initialValues ? (
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(values: ValuesUpdateEventAddress) => {
                            handleUpdate(values)
                        }}
                    >
                        {(props) => {
                            const {
                                values,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                touched,
                                errors,
                            } = props
                            return (
                                <form onSubmit={handleSubmit} noValidate>
                                    <div className='row'>
                                        <div className='col-12 col-lg-4 mb-5'>
                                            <TextInputMask
                                                mask={cepEventMask}
                                                name='ds_cep'
                                                label='CEP'
                                                placeholder='Entre com o CEP'
                                                value={values.ds_cep}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.ds_cep}
                                                touched={touched.ds_cep}
                                                required
                                            />
                                        </div>
                                        <div className='col-12 col-lg-8 mb-5'>
                                            <TextInput
                                                name='ds_reference'
                                                label='Local'
                                                placeholder='Entre com o nome do local'
                                                value={values.ds_reference}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.ds_reference}
                                                touched={touched.ds_reference}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12 col-lg-6 mb-5'>
                                            <TextInput
                                                name='ds_street'
                                                label='Logradouro'
                                                placeholder='Entre com o logradouro'
                                                value={values.ds_street}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.ds_street}
                                                touched={touched.ds_street}
                                                required
                                            />
                                        </div>
                                        <div className='col-12 col-lg-3 mb-5'>
                                            <TextInput
                                                name='ds_number'
                                                label='Número'
                                                placeholder='Número'
                                                value={values.ds_number ?? ''}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.ds_number}
                                                touched={touched.ds_number}
                                            />
                                        </div>
                                        <div className='col-12 col-lg-3 mb-5'>
                                            <TextInput
                                                name='ds_complement'
                                                label='Complemento'
                                                placeholder='Complemento'
                                                value={values.ds_complement ?? ''}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.ds_complement}
                                                touched={touched.ds_complement}
                                            />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12 col-lg-5 mb-5'>
                                            <TextInput
                                                name='ds_neighborhood'
                                                label='Bairro'
                                                placeholder='Entre com o bairro'
                                                value={values.ds_neighborhood}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.ds_neighborhood}
                                                touched={touched.ds_neighborhood}
                                                required
                                            />
                                        </div>
                                        <div className='col-12 col-lg-5 mb-5'>
                                            <TextInput
                                                name='ds_city'
                                                label='Cidade'
                                                placeholder='Entre com a cidade'
                                                value={values.ds_city}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.ds_city}
                                                touched={touched.ds_city}
                                                required
                                            />
                                        </div>
                                        <div className='col-12 col-lg-2 mb-5'>
                                            <TextInput
                                                max={2}
                                                name='ds_state'
                                                label='Estado'
                                                placeholder='UF'
                                                value={values.ds_state}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.ds_state}
                                                touched={touched.ds_state}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <ButtonSubmit
                                        loading={loading}
                                        label={'Atualizar'}
                                        onCancel={() => onClose(false)}
                                    />
                                </form>
                            )
                        }}
                    </Formik>
                ) : (
                    <span className='indicator-progress'>
                        Aguarde...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                )}
            </Modal.Body>
        </Modal>
    )
}
