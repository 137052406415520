export const MoneyMask = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]
export const ufMask = [/\W/, /\W/,]
export const tokenMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
export const codEventMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
export const cepEventMask = [/\d/, /\d/, /\d/, /\d/, /\d/,  '-', /\d/, /\d/, /\d/]
export const CPFMask = [
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
]
export const cellphoneMask = [
    '(',
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
]