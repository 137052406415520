import {useState} from 'react'
import {GetEventModel} from '../models'
import CardHeader from '../../../widgets/cards/CardHeader'
import {parseDate} from '../../../utils/parseDate'
import UpdateEventModal from '../modals/UpdateEventModal'

interface Props {
    event: GetEventModel
    getData: () => void
}

export default function InfoShowEventSubpage({event, getData}: Props) {
    const [isOpenEdit, setIsOpenEdit] = useState(false)

    return (
        <>
            <div className='card'>
                <CardHeader title='Informações gerais'>
                    <button
                        className='btn btn-sm btn-light-info me-2'
                        onClick={() => setIsOpenEdit(true)}
                    >
                        <i className='fa fa-edit'></i>
                        Editar
                    </button>
                </CardHeader>
                <div className='card-body'>
                    <div className='row mb-2'>
                        <div className='col-lg-3'>
                            <a
                                href={
                                    event.data.event.im_banner_path || '/media/logos/logo-azul.png'
                                }
                                target='_blank'
                                rel='noreferrer'
                                className='btn p-0 m-0'
                            >
                                <img
                                    src={
                                        event.data.event.im_banner_path ||
                                        '/media/logos/logo-azul.png'
                                    }
                                    alt='Foto'
                                    className='w-auto h-150px rounded-2'
                                />
                            </a>
                        </div>
                        <div className='col row'>
                            <div className='row mb-2'>
                                <div className='col'>
                                    <label>Nome</label>
                                    <h3>{event.data.event.nm_event}</h3>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-lg-3'>
                                    <label>Código</label>
                                    <h3>{event.data.event.cd_event}</h3>
                                </div>
                                <div className='col-lg-6'>
                                    <label>Período</label>
                                    <h3>
                                        {parseDate(event.data.event.dt_start)} -{' '}
                                        {parseDate(event.data.event.dt_end)}
                                    </h3>
                                </div>
                                <div className='col-lg-3'>
                                    <label>Status</label>
                                    <h3>{event.data.event.fl_status ? 'Ativo' : 'Inativo'}</h3>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-lg-12'>
                                    <label>Observação</label>
                                    <h3>{event.data.event.ds_observation}</h3>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-lg-12'>
                                    <label>Descrição</label>
                                    <h3>{event.data.event.ds_event}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <UpdateEventModal
                isOpen={isOpenEdit}
                onClose={() => setIsOpenEdit(false)}
                event={event.data.event}
                onUpdate={() => getData()}
            />
        </>
    )
}
