import {FormikProps} from 'formik'
import React, {useCallback, useEffect, useState} from 'react'
import Cropper, {Area} from 'react-easy-crop'
import {cropImage, photoUpload, urlToBase64} from '../../utils/image'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    name: string
    label: string
    error?: string
    required?: boolean
    value?: string
    maxZoom?: number
    onChangeImage?: (base64: string | null) => void
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
    formik?: FormikProps<any>
}

export function ImageCropInput({
    name,
    error,
    label,
    required,
    value,
    onBlur,
    formik,
    onChangeImage,
    maxZoom = 10,
}: Props) {
    const [crop, setCrop] = useState({x: 0, y: 0})
    const [zoom, setZoom] = useState(1)
    const initialImage = value || formik?.values[name] || null
    const [isEditing, setIsEditing] = useState(!!initialImage)
    const [preview, setPreview] = useState<string | null>(initialImage)
    const [image, setImage] = useState<string | null>(initialImage)
    const [cropPoints, setCropPoints] = useState<Area>({height: NaN, width: NaN, x: NaN, y: NaN})

    const parseImage = useCallback(async () => {
        if (image?.startsWith('http')) {
            try {
                const base64 = await urlToBase64(image)
                setImage(base64)
                setPreview(base64)
            } catch (error) {
                setImage(null)
                setPreview(image)
                formik?.setFieldValue(name, undefined)
            } finally {
                setIsEditing(false)
            }
        }
    }, [image, formik, name])

    useEffect(() => {
        parseImage()
    }, [parseImage])

    const onCropComplete = async (_: Area, croppedAreaPixels: Area) => {
        if (!preview) return
        setCropPoints(croppedAreaPixels)
    }

    const onFinish = async () => {
        if (!image) return
        const base64 = await cropImage({
            base64: image,
            width: cropPoints.width,
            height: cropPoints.height,
            startX: cropPoints.x,
            startY: cropPoints.y,
        })
        formik?.setFieldValue(name, base64)
        setPreview(base64)
        onChangeImage?.(base64)
        setIsEditing(false)
    }

    const onUpload = (base64: string) => {
        setImage(base64)
        setIsEditing(true)
        setZoom(1)
    }

    const onDelete = () => {
        setPreview(null)
        onChangeImage?.(null)
        setImage(null)
        setZoom(1)
        setIsEditing(false)
        formik?.setFieldValue(name, undefined)
    }

    const handleBlur = (e: React.FocusEvent<any>) => {
        onFinish()
        formik?.handleBlur(e)
        onBlur?.(e)
    }

    return (
        <>
            <label htmlFor={name} className='form-label fs-6 fw-bolder'>
                {label}
                {required && <span className='text-danger'>*</span>}
            </label>
            {isEditing && (
                <div
                    className='position-absolute top-0 start-0 vw-100 vh-100 '
                    onClick={onFinish}
                />
            )}
            <div className='h-100 flex-grow-1 form-label fs-6 fw-bolder d-flex flex-column p-0 m-0 position-relative'>
                <Cropper
                    image={image || '/media/logos/logo-menu-minimize.png'}
                    cropShape='rect'
                    crop={crop}
                    zoom={zoom}
                    aspect={1}
                    maxZoom={maxZoom}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onCropAreaChange={(_, area) => setCropPoints(area)}
                    onZoomChange={setZoom}
                    classes={{
                        containerClassName: !isEditing ? 'd-none' : 'd-flex',
                    }}
                />
                {isEditing ? (
                    <>
                        <button
                            type='button'
                            onClick={onFinish}
                            className='btn btn-primary p-0 m-0 rounded-circle h-30px w-30px d-flex align-items-center justify-content-center position-absolute bottom-0 m-4 border border-1 border-gray'
                        >
                            <i className='fas fa-check p-0 mt-1' />
                        </button>
                        <div className='position-absolute end-0 top-50 translate-middle-y d-flex flex-column m-4 btn-group-vertical'>
                            <button
                                type='button'
                                onClick={() =>
                                    setZoom((prev) => (prev + 1 <= maxZoom ? prev + 1 : prev))
                                }
                                className='btn btn-sm btn-primary btn-icon h-30px w-30px d-flex align-items-center justify-content-center border border-bottom-0 border-1 border-gray'
                            >
                                <i className='fas fa-plus p-0' />
                            </button>
                            <button
                                type='button'
                                onClick={() => setZoom((prev) => (prev <= 1 ? 1 : prev - 1))}
                                className='btn btn-sm btn-primary btn-icon h-30px w-30px d-flex align-items-center justify-content-center border border-top-0 border-1 border-gray'
                            >
                                <i className='fas fa-minus p-0' />
                            </button>
                        </div>
                    </>
                ) : (
                    <>
                        <label
                            htmlFor={name}
                            className='d-flex justify-content-center align-items-center justify-content-center flex-grow-1'
                        >
                            <img
                                id='image-preview'
                                className='rounded-3 border-2 border-light-dark border w-50 '
                                style={{aspectRatio: '1/1', objectFit: 'contain'}}
                                src={preview ? String(preview) : '/media/logos/logo-menu.png'}
                                alt={name}
                            />
                        </label>
                        <label
                            htmlFor={name}
                            className='btn btn-primary p-0 m-0 rounded-circle h-30px w-30px d-flex align-items-center justify-content-center position-absolute bottom-0 m-4 border border-1 border-gray'
                        >
                            <i className='fas fa-camera p-0' />
                        </label>
                        {!!image && (
                            <button
                                type='button'
                                onClick={() => setIsEditing(true)}
                                className='btn btn-primary p-0 m-0 rounded-circle h-30px w-30px d-flex align-items-center justify-content-center position-absolute top-0 end-0 m-4 border border-1 border-gray'
                            >
                                <i className='fas fa-edit ps-2' />
                            </button>
                        )}
                    </>
                )}
                <button
                    type='button'
                    className='btn btn-primary p-0 m-0 rounded-circle h-30px w-30px d-flex align-items-center justify-content-center position-absolute end-0 bottom-0 m-4 border border-1 border-gray'
                    onClick={onDelete}
                >
                    <i className='fas fa-trash p-0' />
                </button>
            </div>
            <input
                type='file'
                accept='image/jpeg, image/png, image/jpg, image/webp'
                name={name}
                id={name}
                onChange={(e) => photoUpload(e, onUpload)}
                className='d-none'
                onBlur={handleBlur}
            />
            {(formik?.touched[name] && formik?.errors[name]) || error ? (
                <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                        <span role='alert' className='text-danger'>
                            {formik?.errors[name] || error}
                        </span>
                    </div>
                </div>
            ) : null}
        </>
    )
}
