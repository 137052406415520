import {Table} from 'react-bootstrap-v5'
import {GetEventModel} from '../models'
import CardHeader from '../../../widgets/cards/CardHeader'
import TableDataConfigShowEventComponent from '../components/TableDataConfigShowEventComponent'

interface Props {
    event: GetEventModel
    getData: () => void
}

export default function ConfigsShowEventSubpage({event}: Props) {
    return (
        <div className='card'>
            <CardHeader title='Configurações' />
            <div className='card-body'>
                <Table
                    responsive
                    bordered
                    className='table table-hover table-row-dashed table-col-dashed align-middle'
                    width='100%'
                >
                    <thead>
                        <tr className='fw-bolder text-muted bg-light'>
                            <td>Configuração</td>
                            <td className='text-center'>Status</td>
                        </tr>
                    </thead>
                    <tbody>
                        <TableDataConfigShowEventComponent
                            title='Auto-cadastro'
                            status={event.data.event.configs.fl_external_register}
                        />
                        <TableDataConfigShowEventComponent
                            title='Cartão virtual'
                            status={event.data.event.configs.fl_virtual_card}
                        />
                        <TableDataConfigShowEventComponent
                            title='Cronograma'
                            status={event.data.event.configs.fl_schedule}
                        />
                        <TableDataConfigShowEventComponent
                            title='Ingressos'
                            status={event.data.event.configs.fl_ticket}
                        />
                        <TableDataConfigShowEventComponent
                            title='Promoções'
                            status={event.data.event.configs.fl_sale}
                        />
                        <TableDataConfigShowEventComponent
                            title='Recargas'
                            status={event.data.event.configs.fl_purchase}
                        />
                        <TableDataConfigShowEventComponent
                            title='Sorteios'
                            status={event.data.event.configs.fl_raffle}
                        />
                    </tbody>
                </Table>
            </div>
        </div>
    )
}
