export function parseDate(date: string | null) {
    if (date) {
        const [year, month, day] = date.split('-')
        return day ? day + '/' + month + '/' + year : null
    }
    return null
}

export function parseDateBr(date: string | null) {
    if (date) {
        const [day, month, year] = date.split('/')
        return year ? year + '-' + month + '-' + day : null
    }
    return null
}