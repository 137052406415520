import {lazy} from 'react'
import {Route, Switch} from 'react-router-dom'

export function ExportRoutes() {


    /*Rotas para Recargas*/
    const DatatableExports = lazy(() => import('../../pages/exports/excel/DatatableExport'))

    return (
        <Switch>

            {/*Rotas para Recargas*/}
            <Route path='/exportacoes/excel' component={DatatableExports} />

        </Switch>
    )

}
