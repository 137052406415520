interface Props {
    code: Number
    title: String
}

export default function EmptyPageWidget({code = 404, title = 'Não encontrado'}: Props) {
    return (
        <div className='row'>
            <div className="col-lg-12">
                <span>{code}</span>
            </div>
            <div className="col-lg-12">
                <span>{title}</span>
            </div>
        </div>
    )
}
