export function parseToNull(value: string | null | number) {
    if (typeof value === 'string') {
        if (value === '-1' || value === undefined || value === null || value === '') {
            return null
        }
        return value
    } else if (typeof value === 'number') {
        if (value === -1 || value === undefined || value === null || value === 0) {
            return null
        }
        return String(value)
    }
    return null
}
