interface Props extends React.InputHTMLAttributes<HTMLButtonElement> {
    loading?: boolean
    onConfirmClick?: () => void
    onCancel?: () => void
    label?: string
    isSubmit?: boolean
    className?: string
}
export function ButtonSubmit({
    loading,
    onCancel,
    label = 'Cadastrar',
    isSubmit = true,
    onConfirmClick = () => {},
    className = '',
}: Props) {
    return (
        <div className={'row ' + className}>
            <div className='col-12 text-end'>
                {onCancel && (
                    <button
                        onClick={() => onCancel()}
                        type='button'
                        className='btn btn-secondary me-2'
                        style={{width: 'max-content'}}
                    >
                        Cancelar
                    </button>
                )}
                {isSubmit ? (
                    <button
                        type='submit'
                        className='btn btn-primary'
                        style={{width: 'max-content'}}
                    >
                        {!loading && <span className='indicator-label'>{label}</span>}
                        {loading && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                                Aguarde...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2' />
                            </span>
                        )}
                    </button>
                ) : (
                    <button
                        onClick={() => onConfirmClick()}
                        className='btn btn-primary'
                        style={{width: 'max-content'}}
                        type="button"
                    >
                        {!loading && <span className='indicator-label'>{label}</span>}
                        {loading && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                                Aguarde...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2' />
                            </span>
                        )}
                    </button>
                )}
            </div>
        </div>
    )
}
