import {lazy} from 'react'
import {Route, Switch} from 'react-router-dom'

export function SettingsRoutes() {
    /*Rotas para Locais*/
    const DatatableLocals = lazy(() => import('../../pages/settings/local/DatatableLocal'))

    /*Rotas para Produtos*/
    const DatatableProducts = lazy(() => import('../../pages/settings/product/DatatableProduct'))

    /*Rotas para Formas de Pagamento*/
    const DatatablePurchaseType = lazy(
        () => import('../../pages/settings/purchase_type/DatatablePurchaseType')
    )

    /*Rotas para Usuários*/
    const DatatableUsers = lazy(() => import('../../pages/settings/access/user/DatatableUser'))
    const StoreUser = lazy(() => import('../../pages/settings/access/user/StoreUser'))
    const ShowUser = lazy(() => import('../../pages/settings/access/user/ShowUser'))
    const UpdateUser = lazy(() => import('../../pages/settings/access/user/UpdateUser'))

    /*Rotas para Usuários*/
    const DatatablePrivileges = lazy(
        () => import('../../pages/settings/access/privilege/DatatablePrivilege')
    )
    const StorePrivilege = lazy(
        () => import('../../pages/settings/access/privilege/StorePrivilege')
    )
    const UpdatePrivilege = lazy(
        () => import('../../pages/settings/access/privilege/UpdatePrivilege')
    )

    /*Rotas para Configs*/
    const DatatableConfigs = lazy(() => import('../../pages/settings/configs/DatatableConfig'))

    return (
        <Switch>
            {/*Rotas para Locais*/}
            <Route path='/configuracoes/locais' component={DatatableLocals} />

            {/*Rotas para Produtos*/}
            <Route path='/configuracoes/produtos' component={DatatableProducts} />

            {/*Rotas para Formas de Pagamento*/}
            <Route path='/configuracoes/formas-de-pagamento' component={DatatablePurchaseType} />

            {/*Rotas para Usuários*/}
            <Route path='/configuracoes/acessos/usuarios/cadastrar' component={StoreUser} />
            <Route path='/configuracoes/acessos/usuarios/:id/atualizar' component={UpdateUser} />
            <Route path='/configuracoes/acessos/usuarios/:id' component={ShowUser} />
            <Route path='/configuracoes/acessos/usuarios' component={DatatableUsers} />

            {/*Rotas para Perfis*/}
            <Route path='/configuracoes/acessos/perfis/:id/atualizar' component={UpdatePrivilege} />

            <Route path='/configuracoes/acessos/perfis/cadastrar' component={StorePrivilege} />
            <Route path='/configuracoes/acessos/perfis' component={DatatablePrivileges} />

            {/*Rotas para Perfis*/}
            <Route path='/configuracoes/evento' component={DatatableConfigs} />
        </Switch>
    )
}
