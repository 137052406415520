import React, {useCallback, useEffect, useState} from 'react'
import axios from 'axios'
import queryString from 'query-string'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {GetTicketDetailModel} from './models'
import {SimpleEventModel, SimpleTicketPurchaseModel, SimpleTicketSaleModel} from '../../../models'
import {Spinner} from 'react-bootstrap-v5'
import {ErrorNotFoundTicket} from '../../errors/ErrorNotFoundTicket'
import {useLocation} from 'react-router-dom'
import parseMoney from '../../../utils/parseMoney'
import QRCode from 'react-qr-code'

export function TicketContent() {
    const {search} = useLocation()

    const [loading, setLoading] = useState(true)
    const [ticketDetail, setTicketDetail] = useState<SimpleTicketPurchaseModel>()
    const [event, setEvent] = useState<SimpleEventModel>()

    const getTicketDetail = useCallback(async () => {
        try {
            const values = queryString.parse(search)
            const {data} = await axios.post<GetTicketDetailModel>('ticket-purchase-detail', {
                event_code: values.event_code ?? '',
                code: values.code ?? '',
                crypt_key: values.crypt_key ?? '',
            })
            setTicketDetail(data.data.ticket_purchase)
            setEvent(data.data.event)
        } finally {
            setLoading(false)
        }
    }, [search])

    useEffect(() => {
        getTicketDetail()
    }, [getTicketDetail])

    return (
        <div
            className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
            style={{
                backgroundImage: `url(${toAbsoluteUrl('/media/bgs/bg-login.png')})`,
                backgroundSize: 'cover',
            }}
        >
            {/* begin::Content */}
            <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
                {/* begin::Wrapper */}
                <div
                    className='bg-white rounded shadow-sm p-10 mx-auto'
                    style={{maxWidth: '1000px'}}
                >
                    {loading ? (
                        <div className='d-flex justify-content-center align-items-center h-100 w-100 p-20'>
                            <Spinner className='text-primary' animation='grow' />
                        </div>
                    ) : ticketDetail !== undefined && event !== undefined ? (
                        <>
                            {/* {ticketDetail.created_at} */}
                            <div className='text-center'>
                                <button
                                    onClick={() => {
                                        window.print()
                                    }}
                                    className='btn btn-sm btn-light-primary'
                                >
                                    <i className='fa fa-plus-circle'></i>
                                    Imprimir ingresso
                                </button>
                            </div>
                            <div className='mb-10 text-center'>
                                <img
                                    alt='Logo'
                                    src={toAbsoluteUrl('/media/logos/logo-detail-ticket.png')}
                                    className='h-100px'
                                />
                            </div>
                            <h2>Informações do evento</h2>
                            <div className='row mb-2'>
                                <div className='col-lg-3'>
                                    <label>Cód.</label>
                                    <h3>{event.cd_event}</h3>
                                </div>
                                <div className='col-lg-9'>
                                    <label>Evento</label>
                                    <h3>{event.nm_event}</h3>
                                </div>
                            </div>
                            <h2>Informações da compra</h2>
                            <div className='row mb-2'>
                                <div className='col-lg-3'>
                                    <label>Cód. da compra</label>
                                    <h3>{ticketDetail.id}</h3>
                                </div>
                                <div className='col-lg-3'>
                                    <label>Preço</label>
                                    <h3>{parseMoney(ticketDetail.vn_purchase.toString())}</h3>
                                </div>
                                <div className='col-lg-3'>
                                    <label>Forma de pagamento</label>
                                    <h3>{ticketDetail.purchase_type.nm_purchase_type}</h3>
                                </div>
                                <div className='col-lg-3'>
                                    <label>Data/Hora</label>
                                    <h3>{ticketDetail.created_at}</h3>
                                </div>
                            </div>
                            <div className='row mb-2'></div>
                            <h2>Ingressos</h2>
                            {ticketDetail.ticket_sales.map(
                                (obj: SimpleTicketSaleModel, index: number) => (
                                    <div key={index} className='border bg-light rounded-2 p-5 mt-5'>
                                        <div className='row'>
                                            <div className='col-lg-3 col-12'>
                                                <div className='w-150px'>
                                                    <QRCode
                                                        size={256}
                                                        style={{
                                                            height: 'auto',
                                                            maxWidth: '128px',
                                                            width: '100%',
                                                        }}
                                                        value={obj.qr_content}
                                                        viewBox={`0 0 256 256`}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className='col-lg-9 col-12'
                                                style={{wordBreak: 'break-all'}}
                                            >
                                                <div className='row mb-2'>
                                                    <div className='col-lg-12'>
                                                        <label>Cód. do Ingresso</label>
                                                        <h3>{obj.id}</h3>
                                                    </div>
                                                    <div className='col-lg-12'>
                                                        <label>Ingresso</label>
                                                        <h3>
                                                            ({parseMoney(obj.vn_price)}){' '}
                                                            {obj.ticket.nm_ticket}
                                                        </h3>
                                                    </div>
                                                </div>
                                                <div className='row mb-2'>
                                                    <div className='col-lg-3'>
                                                        <label>Documento</label>
                                                        <h3>{obj.ds_document}</h3>
                                                    </div>
                                                    <div className='col-lg-9'>
                                                        <label>Comprador</label>
                                                        <h3>{obj.nm_buyer}</h3>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <label>Verificador de autenticidade</label>
                                                    <h3>{window.location.href}</h3>
                                                    <div className='text-end'>
                                                        <QRCode
                                                            size={48}
                                                            style={{
                                                                height: 'auto',
                                                                maxWidth: '64px',
                                                                width: '100%',
                                                            }}
                                                            value={window.location.href}
                                                            viewBox={`0 0 256 256`}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            )}
                        </>
                    ) : (
                        <ErrorNotFoundTicket />
                    )}
                </div>
                {/* end::Wrapper */}
            </div>
            {/* end::Content */}
            {/* begin::Footer */}
            <div className='d-flex flex-center flex-column-auto pb-10'>
                <div className='d-flex align-items-center fw-bold fs-6 text-white'>
                    <span className='me-2'>{new Date().getFullYear()} &copy;</span>
                    <a
                        href='https://kachin.com.br/'
                        target='_blank'
                        rel='noreferrer'
                        className='text-white text-hover-primary'
                    >
                        Kachin - Soluções para eventos
                    </a>
                </div>
            </div>
            {/* end::Footer */}
        </div>
    )
}
