/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

export function AuthPage() {
    useEffect(() => {
        document.body.classList.add('bg-white')
        return () => {
            document.body.classList.remove('bg-white')
        }
    }, [])

    return (
        <div
            className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
            style={{
                backgroundImage: `url(${toAbsoluteUrl('/media/bgs/bg-login.png')})`,
                backgroundSize: 'cover',
            }}
        >
            {/* begin::Content */}
            <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
                {/* begin::Logo */}
                <div className='mb-10'>
                    <img
                        alt='Logo'
                        src={toAbsoluteUrl('/media/logos/logo.png')}
                        className='h-45px'
                    />
                </div>
                {/* end::Logo */}
                {/* begin::Wrapper */}
                <div className='w-lg-500px bg-white rounded shadow-sm p-10 mx-auto'>
                    <Switch>
                        <Route path='/autenticacao/entrar' component={Login} />
                        <Redirect from='/auth' exact={true} to='/autenticacao/entrar' />
                        <Redirect to='/autenticacao/entrar' />
                    </Switch>
                </div>
                {/* end::Wrapper */}
            </div>
            {/* end::Content */}
            {/* begin::Footer */}
            <div className='d-flex flex-center flex-column-auto pb-10'>
                <div className='d-flex align-items-center fw-bold fs-6 text-white'>
                    <span className='me-2'>{new Date().getFullYear()} &copy;</span>
                    <a
                        href='https://kachin.com.br/'
                        target='_blank'
                        rel='noreferrer'
                        className='text-white text-hover-primary'
                    >
                        Kachin - Soluções para eventos
                    </a>
                </div>
            </div>
            {/* end::Footer */}
        </div>
    )
}
