import axios from 'axios'
import {Formik} from 'formik'
import {useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {toast} from 'react-toastify'
import * as Yup from 'yup'

import {EventModel, ValuesUpdateEvent} from '../models'
import {ImageCropInput} from '../../../widgets/form/ImageCropInput'
import {ButtonSubmit, TextArea, TextInput} from '../../../widgets/form'

interface Props {
    onClose: (isOpen: boolean) => void
    event: EventModel
    onUpdate: () => void
    isOpen: boolean
}

const validationSchema = Yup.object({
    nm_event: Yup.string().required('Campo obrigatório!'),
    ds_observation: Yup.string().required('Campo obrigatório!'),
    ds_event: Yup.string().required('Campo obrigatório!'),
})

export default function UpdateEventModal({onClose, isOpen, event, onUpdate}: Props) {
    const [loading, setLoading] = useState(false)

    const initialValues = {
        nm_event: event.nm_event || '',
        ds_observation: event.ds_observation || '',
        ds_event: event.ds_event || '',
        im_banner_path: event.im_banner_path,
    }

    async function handleUpdate(values: ValuesUpdateEvent) {
        try {
            setLoading(true)
            const {data} = await axios.put('/event/update/data', values)
            console.log(data)
            toast.success(data.message)
            onUpdate()
            onClose(false)
        } finally {
            setLoading(false)
        }
    }
    return (
        <Modal
            onHide={() => onClose(false)}
            show={isOpen}
            aria-labelledby='contained-modal-title-center'
            size='lg'
            centered
        >
            <Modal.Header>
                <Modal.Title id='contained-modal-title-center'>Editar dados do evento</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {initialValues ? (
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(values: ValuesUpdateEvent) => {
                            handleUpdate(values)
                        }}
                    >
                        {(props) => {
                            const {
                                values,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                touched,
                                errors,
                            } = props
                            return (
                                <form onSubmit={handleSubmit} noValidate>
                                    <div className='row'>
                                        <div className='col-lg-4 h-200px'>
                                            <ImageCropInput
                                                name='im_banner_path'
                                                label='Imagem do evento (256x256 pixels)'
                                                formik={props}
                                            />
                                        </div>
                                        <div className='col-lg-8'>
                                            <div className='row'>
                                                <div className='col-12 mb-5'>
                                                    <TextInput
                                                        name='nm_event'
                                                        label='Nome do Evento'
                                                        placeholder='Ex: Evento de apresentação'
                                                        value={values.nm_event}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.nm_event}
                                                        touched={touched.nm_event}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-lg-12 mb-5'>
                                                    <TextInput
                                                        name='ds_observation'
                                                        label='Observação'
                                                        placeholder='Ex: Todos os finais de semana'
                                                        value={values.ds_observation}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.ds_observation}
                                                        touched={touched.ds_observation}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-lg-12 mb-5'>
                                                    <TextArea
                                                        name='ds_event'
                                                        label='Descrição'
                                                        placeholder='Entre com uma descrição do evento'
                                                        value={values.ds_event}
                                                        rows={5}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <ButtonSubmit
                                        loading={loading}
                                        label={'Atualizar'}
                                        onCancel={() => onClose(false)}
                                    />
                                </form>
                            )
                        }}
                    </Formik>
                ) : (
                    <span className='indicator-progress'>
                        Aguarde...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                )}
            </Modal.Body>
        </Modal>
    )
}
