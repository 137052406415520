import {lazy} from 'react'
import {Route, Switch} from 'react-router-dom'

export function ManagmentRoutes() {
    /*Rotas para Cartões*/
    const DatatableCards = lazy(() => import('../../pages/management/card/DatatableCard'))
    const ShowCard = lazy(() => import('../../pages/management/card/ShowCard'))

    /*Rotas para Receitas*/
    const DatatableRevenueType = lazy(
        () => import('../../pages/management/revenue/DatatableRevenueType')
    )
    const DatatableRevenue = lazy(() => import('../../pages/management/revenue/DatatableRevenue'))

    /*Rotas para Custos*/
    const DatatableCostType = lazy(() => import('../../pages/management/costs/DatatableCostType'))
    const DatatableCost = lazy(() => import('../../pages/management/costs/DatatableCost'))

    /*Rotas para Custos*/
    const DatatableTicket = lazy(
        () => import('../../pages/management/box_office/ticket/DatatableTicket')
    )
    const DatatableTicketPurchase = lazy(
        () => import('../../pages/management/box_office/ticket_purchase/DatatableTicketPurchase')
    )
    const DatatableTicketSale = lazy(
        () => import('../../pages/management/box_office/ticket_sale/DatatableTicketSale')
    )
    const DatatableTicketSaleUse = lazy(
        () => import('../../pages/management/box_office/ticket_sale_use/DatatableTicketSaleUse')
    )

    return (
        <Switch>
            {/*Rotas para Cartões*/}
            <Route path='/gestao/cartoes/:id' component={ShowCard} />
            <Route path='/gestao/cartoes' component={DatatableCards} />

            {/*Rotas para Receitas*/}
            <Route path='/gestao/receitas/categorias' component={DatatableRevenueType} />
            <Route path='/gestao/receitas/lancamentos' component={DatatableRevenue} />

            {/*Rotas para Custos*/}
            <Route path='/gestao/custos/categorias' component={DatatableCostType} />
            <Route path='/gestao/custos/lancamentos' component={DatatableCost} />

            {/*Rotas para Bilheteria*/}
            <Route path='/gestao/bilheteria/cadastros' component={DatatableTicket} />
            <Route path='/gestao/bilheteria/compras' component={DatatableTicketPurchase} />
            <Route path='/gestao/bilheteria/ingressos' component={DatatableTicketSale} />
            <Route path='/gestao/bilheteria/leituras' component={DatatableTicketSaleUse} />
        </Switch>
    )
}
