import {lazy} from 'react'
import {Route, Switch} from 'react-router-dom'

export function ReportRoutes() {
    /*Rotas para Recargas*/
    const DatatablePurchases = lazy(() => import('../../pages/reports/purchase/DatatablePurchase'))
    const DatatablePurchasesSimple = lazy(
        () => import('../../pages/reports/purchase/DatatablePurchaseSimple')
    )
    const DatatablePurchasesType = lazy(
        () => import('../../pages/reports/purchase/DatatablePurchaseType')
    )
    const DatatablePurchasesUser = lazy(
        () => import('../../pages/reports/purchase/DatatablePurchaseUser')
    )

    /*Rotas para Vendas*/
    const DatatableOrders = lazy(() => import('../../pages/reports/order/DatatableOrder'))
    const DatatableOrdersSimple = lazy(
        () => import('../../pages/reports/order/DatatableOrderSimple')
    )
    const DatatableOrdersThird = lazy(() => import('../../pages/reports/order/DatatableOrderThird'))
    const DatatableOrdersProduct = lazy(
        () => import('../../pages/reports/order/DatatableOrderProduct')
    )
    const DatatableOrdersUser = lazy(() => import('../../pages/reports/order/DatatableOrderUser'))
    const DatatableOrdersUserProduct = lazy(
        () => import('../../pages/reports/order/DatatableOrderUserProduct')
    )
    const DatatableOrderFull = lazy(() => import('../../pages/reports/order/DatatableOrderFull'))

    /*Rotas para Estoque*/
    const DatatableStock = lazy(() => import('../../pages/reports/stock/DatatableStock'))

    /*Rotas para Estoque*/
    const DatatableCsat = lazy(() => import('../../pages/reports/csat/DatatableCsat'))

    /*Rotas para Completo*/
    const DatatableConsolidated = lazy(
        () => import('../../pages/reports/consolidated/DatatableConsolidated')
    )

    return (
        <Switch>
            {/*Rotas para Recargas*/}
            <Route path='/relatorios/recargas/completo' component={DatatablePurchases} />
            <Route path='/relatorios/recargas/simples' component={DatatablePurchasesSimple} />
            <Route path='/relatorios/recargas/tipos' component={DatatablePurchasesType} />
            <Route path='/relatorios/recargas/usuarios' component={DatatablePurchasesUser} />

            {/*Rotas para Vendas*/}
            <Route path='/relatorios/vendas/completo' component={DatatableOrders} />
            <Route path='/relatorios/vendas/simples' component={DatatableOrdersSimple} />
            <Route path='/relatorios/vendas/terceirizadas' component={DatatableOrdersThird} />
            <Route path='/relatorios/vendas/produtos' component={DatatableOrdersProduct} />
            <Route path='/relatorios/vendas/usuarios' component={DatatableOrdersUser} />
            <Route
                path='/relatorios/vendas/usuarios-produtos'
                component={DatatableOrdersUserProduct}
            />
            <Route path='/relatorios/vendas/local' component={DatatableOrderFull} />

            {/*Rotas para Estoque*/}
            <Route path='/relatorios/estoque' component={DatatableStock} />

            {/*Rotas para CSAT*/}
            <Route path='/relatorios/csat' component={DatatableCsat} />
            <Route path='/relatorios/consolidado' component={DatatableConsolidated} />
        </Switch>
    )
}
