import {FormikProps} from 'formik'
import React from 'react'
import {Tooltip} from 'react-bootstrap-v5'
import OverlayTrigger from 'react-bootstrap-v5/lib/esm/OverlayTrigger'

interface Props extends React.SelectHTMLAttributes<HTMLSelectElement> {
    touched?: boolean
    error?: string
    name: string
    label?: string
    value?: string
    onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void
    onBlur?: (event: React.FocusEvent<HTMLSelectElement>) => void
    required?: boolean
    disabled?: boolean
    placeholder?: string
    formik?: FormikProps<any>
    children: React.ReactNode
    infoText?: string
}
export function Select({
    touched,
    error,
    name,
    label,
    value = '',
    onChange,
    onBlur,
    required = false,
    children,
    placeholder,
    formik,
    disabled = false,
    infoText,
    ...props
}: Props) {
    return (
        <>
            {label && (
                <label htmlFor={name} className='form-label fs-6 fw-bolder'>
                    {label}
                    {required && <span className='text-danger'>*</span>}
                    {infoText && (
                        <OverlayTrigger
                            key='bottom'
                            placement='bottom'
                            overlay={<Tooltip id='tooltipCard'>{infoText}</Tooltip>}
                        >
                            <span className='ms-2'>
                                <i className='fas fa-info-circle text-warning'></i>
                            </span>
                        </OverlayTrigger>
                    )}
                </label>
            )}
            <select
                className='form-select form-select-solid'
                id={name}
                name={name}
                value={value || formik?.values[name]}
                onChange={onChange || formik?.handleChange}
                onBlur={formik ? formik.handleBlur : onBlur}
                {...props}
            >
                <option value={''}>{placeholder || 'Selecione...'}</option>
                {children}
            </select>
            {(formik?.touched[name] && formik?.errors[name]) || error ? (
                <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                        <span role='alert' className='text-danger'>
                            {formik?.errors[name] || error}
                        </span>
                    </div>
                </div>
            ) : null}
        </>
    )
}
